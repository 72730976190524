import React from 'react'
import '../assets/css/Home.styl'
function Home() {
        return (
            <div className="home">
               <div className="banner">
               </div>
               <div className="icon-box">
               <div className="about-title fs-36 mt-36 bold">
               </div>
                <ul className="flex-justify-center">
                    <li className='icon-item'>
                        <div className="flex-column flex-items-center">
                            <img src={require("../assets/images/logo1.png")} alt="" width="118" height="118"/>
                            <div className="fs-24 mt-24">
                            流星清理卫士
                            </div>
                            <div className="fs-16 fc-w6  mt-16">
                            清理杀毒 又快又准
                            </div>
                        </div>
                    </li>
                    <li className='icon-item'>
                        <div className="flex-column flex-items-center">
                            <img src={require("../assets/images/logo2.png")} alt="" width="118" height="118"/>
                            <div className="fs-24 mt-24">
                            星秀酷炫来电秀
                            </div>
                            <div className="fs-16 fc-w6  mt-16">
                            打造你的专属来电秀
                            </div>
                        </div>
                    </li> 
                    <li className='icon-item'>
                        <div className="flex-column flex-items-center">
                            <img src={require("../assets/images/logo3.png")} alt="" width="118" height="118"/>
                            <div className="fs-24 mt-24">
                            简变P图
                            </div>
                            <div className="fs-16 fc-w6  mt-16">
                            一步带你玩转P图
                            </div>
                        </div>
                    </li>            
                </ul>
                <ul className='flex-justify-center'>
                    <li className='icon-item'>
                        <div className="flex-column flex-items-center">
                            <img src={require("../assets/images/logo8.png")} alt="" width="118" height="118"/>
                            <div className="fs-24 mt-24">
                            立知天气
                            </div>
                            <div className="fs-16 fc-w6  mt-16">
                            立即知晓每日天气
                            </div>
                        </div>
                    </li>  
                    <li className='icon-item'>
                        <div className="flex-column flex-items-center">
                            <img src={require("../assets/images/logo5.png")} alt="" width="118" height="118"/>
                            <div className="fs-24 mt-24">
                            万能魔音变声器
                            </div>
                            <div className="fs-16 fc-w6  mt-16">
                            声音百变趣味无穷
                            </div>
                        </div>
                    </li>
                    <li className='icon-item'>
                        <div className="flex-column flex-items-center">
                            <img src={require("../assets/images/logo6.png")} alt="" width="118" height="118"/>
                            <div className="fs-24 mt-24">
                            巨牛内存管家 
                            </div>
                            <div className="fs-16 fc-w6  mt-16">
                            您的贴身手机管家
                            </div>
                        </div>
                    </li>
                </ul>
                <ul className='flex-justify-center'>
                    <li className='icon-item'>
                        <div className="flex-column flex-items-center">
                            <img src={require("../assets/images/logo7.png")} alt="" width="118" height="118"/>
                            <div className="fs-24 mt-24">
                            闪电文件管家
                            </div>
                            <div className="fs-16 fc-w6  mt-16">
                            让手机丝般畅快 
                            </div>
                        </div>
                    </li>

                    {/* <li className='icon-item'>
                        <div className="flex-column flex-items-center">
                            <img src={require("../assets/images/logo4.png")} alt="" width="118" height="118"/>
                            <div className="fs-24 mt-24">
                            手机WiFi快速连
                            </div>
                            <div className="fs-16 fc-w6  mt-16">
                            快速连接网络世界
                            </div>
                        </div>
                    </li>  */}
                </ul>
               </div>
            </div>
        );
    }
export default Home;