import React from 'react'
import '../assets/css/Product.styl'
function Product() {
        return (
            <div className="product">
               <div className="flex-justify-center box">
                    <div className="flex-column content  flex-justify-center">
                        <img src={require("../assets/images/logo1.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        流星清理卫士
                        </div>
                        <div className="fs-20 mt-24 bold-200">
                        便捷的手机清理软件，有效地解决手机使用过程中卡顿、发热、内存不足的问题
                        </div>
                    </div>
                    <div>
                        <img src={require("../assets/images/phone_1.png")} alt="" width="324" height="514"/>
                    </div>
               </div>

               <div className="flex-justify-center box">
                    <div>
                        <img src={require("../assets/images/phone_2.png")} alt="" width="324" height="514"/>
                    </div>
                    <div className="flex-column content-right  flex-justify-center">
                        <img src={require("../assets/images/logo2.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        星秀酷炫来电秀
                        </div>
                        <div className="fs-20 mt-24 bold-200 ">
                        让你的来电秀不限于单调的系统默认，热门潮流主题，轻松设置个性有趣的来电视频
                        </div>
                    </div>
               </div>

               <div className="flex-justify-center box">
                    <div className="flex-column content  flex-justify-center">
                        <img src={require("../assets/images/logo3.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        简变P图
                        </div>
                        <div className="fs-20 mt-24 bold-200">
                        一键换背景，艺术滤镜，瞬间P掉路人！ 海量模板，让你一键生成证件照、节日贺卡、头像、表情包、海报、Vlog封面
                        </div>
                    </div>
                    <div>
                        <img src={require("../assets/images/phone_3.png")} alt="" width="324" height="514"/>
                    </div>
               </div>

               <div className="flex-justify-center box">
                    <div>
                        <img src={require("../assets/images/phone_8.png")} alt="" width="324" height="514"/>
                    </div>
                    <div className="flex-column content-right  flex-justify-center">
                        <img src={require("../assets/images/logo8.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        立知天气
                        </div>
                        <div className="fs-20 mt-24 bold-200 ">
                        立知天气可以帮助您快捷查询天气状况，可快速查询全国天气、15日天气、空气质量，还有天气预警、生活指数建议、日历等。
                        </div>
                    </div>
               </div>

               <div className="flex-justify-center box">
                    <div className="flex-column content  flex-justify-center">
                        <img src={require("../assets/images/logo5.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        万能魔音变声器
                        </div>
                        <div className="fs-20 mt-24 bold-200">
                        一款有趣、专业、个性化十足的变声器产品，适用于游戏、聊天、语音通话等多种场景，用一种全新的方式，让你的声音变得惟妙惟肖，改变声音从现在开始。
                        </div>
                    </div>
                    <div>
                        <img src={require("../assets/images/phone_5.png")} alt="" width="324" height="514"/>
                    </div>
               </div>

               <div className="flex-justify-center box">
                    <div>
                        <img src={require("../assets/images/phone_6.png")} alt="" width="324" height="514"/>
                    </div>
                    <div className="flex-column content-right  flex-justify-center">
                        <img src={require("../assets/images/logo6.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        巨牛内存管家
                        </div>
                        <div className="fs-20 mt-24 bold-200 ">
                        巨牛内存管家，智能清理手机，能够轻松清理手机中的各种垃圾，让您的手机感受飞一般的体验！一键清理，让手机速度迅速提升，彻底告别卡顿！
                        </div>
                    </div>
               </div>

               <div className="flex-justify-center box">
                    <div className="flex-column content  flex-justify-center">
                        <img src={require("../assets/images/logo7.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        闪电文件管家
                        </div>
                        <div className="fs-20 mt-24 bold-200">
                        闪电文件管家，全面管理手机，解决手机各种垃圾及病毒问题，让手机彻底干净彻底安全！智能扫描，1秒钟让手机恢复运行速度！
                        </div>
                    </div>
                    <div>
                        <img src={require("../assets/images/phone_7.png")} alt="" width="324" height="514"/>
                    </div>
               </div>

               {/* <div className="flex-justify-center box">
                    <div>
                        <img src={require("../assets/images/phone_4.png")} alt="" width="324" height="514"/>
                    </div>
                    <div className="flex-column content-right  flex-justify-center">
                        <img src={require("../assets/images/logo4.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        手机WiFi快速连
                        </div>
                        <div className="fs-20 mt-24 bold-200 ">
                        手机WiFi快速连是一款专注的增强Wi-Fi网络信号、网络，管理Wi-Fi密码的实用工具
                        </div>
                    </div>
               </div> */}

            </div>
        );
    }
export default Product;